import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import PatientOverview from "./patientoverview";
import PatientIntake from "./patientintake";
import { getFirestore, doc, getDoc, collection, getDocs, query, where,  Timestamp, arrayUnion, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import MenuPane from "./MenuPane";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import Tooltip from "@mui/material/Tooltip";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ProviderHome = () => {
  const [providerName, setProviderName] = useState("");
  const [clinics, setClinics] = useState([]);
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [openPatientIntake, setOpenPatientIntake] = useState(false);
  const [clinicsOpen, setClinicsOpen] = useState(true);
  const [openSessionConfirmationDialog, setOpenSessionConfirmationDialog] = useState(false);
  const [tempSelectedPatient, setTempSelectedPatient] = useState(null); // Store patient temporarily for confirmation
  const [showInactivityDialog, setShowInactivityDialog] = useState(false);
  const [isExitSessionDialogOpen, setIsExitSessionDialogOpen] = useState(false);
  const [tempClinicId, setTempClinicId] = useState(null);
  const inactivityTimeout = useRef(null);
  const [countdown, setCountdown] = useState(300);
  const INACTIVITY_TIME = 300000; // 5 minutes in milliseconds
  const countdownTimer = useRef(null);

  
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Fetch provider data and set up inactivity timeout
    const fetchProviderData = async () => {
      const userUID = sessionStorage.getItem("userUID");
      if (!userUID) return;
  
      try {
        const docRef = doc(db, "providers", userUID);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const providerData = docSnap.data();
          const assignedClinics = providerData.assigned_clinics;
          const clinicIds = assignedClinics.map((clinic) => clinic.clinic_id);
          const patientIds = assignedClinics.flatMap((clinic) => clinic.assigned_patients);
  
          if (clinicIds.length > 0) {
            const clinicsCollectionRef = collection(db, "clinics");
            const clinicsQuery = query(clinicsCollectionRef, where("__name__", "in", clinicIds));
            const clinicsSnapshot = await getDocs(clinicsQuery);
            const clinicsList = clinicsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setClinics(clinicsList);
  
            if (!selectedClinic && clinicsList.length > 0) {
              setSelectedClinic(clinicsList[0].id);
            }
          }
  
          if (patientIds.length > 0 && selectedClinic) {
            const patientsCollectionRef = collection(db, `clinics/${selectedClinic}/patients`);
            const patientsQuery = query(patientsCollectionRef, where("__name__", "in", patientIds));
            const patientsSnapshot = await getDocs(patientsQuery);
            const patientsList = patientsSnapshot.docs.map((doc) => {
              const patientData = doc.data();
  
              // Check if there is a video call completed this month
              const isVideoCallThisMonth = patientData.video_calls?.some((call) => {
                const completedDate = call.completed_date?.toDate();
                return (
                  completedDate &&
                  completedDate.getMonth() === currentMonth &&
                  completedDate.getFullYear() === currentYear
                );
              });
  
              return {
                id: doc.id,
                ...patientData,
                needsVideoCall: !isVideoCallThisMonth, // Set flag if no video call this month
              };
            });
            setPatients(patientsList);
            setFilteredPatients(patientsList);
          } else {
            setPatients([]);
            setFilteredPatients([]);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };
  
    // Run the initial data fetch
    fetchProviderData();
  
  }, [selectedClinic]);

  useEffect(() => {
    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
      setShowInactivityDialog(false); // Hide inactivity dialog
      clearTimeout(inactivityTimeout.current);
      clearInterval(countdownTimer.current);
      setCountdown(300); // Reset countdown to 5 minutes
      inactivityTimeout.current = setTimeout(() => {
        setShowInactivityDialog(true); // Show inactivity dialog after 5 minutes of inactivity
        startCountdown(); // Start countdown for session end
      }, INACTIVITY_TIME);
    };
  
    // Function to start the countdown
    const startCountdown = () => {
      let timeLeft = 30; // 5 minutes countdown in seconds
      countdownTimer.current = setInterval(() => {
        timeLeft -= 1;
        setCountdown(timeLeft); // Update countdown state
        if (timeLeft <= 0) {
          clearInterval(countdownTimer.current); // Stop countdown
          endSessionDueToInactivity(); // End session
        }
      }, 1000);
    };
  
    // Function to end the session due to inactivity
    const endSessionDueToInactivity = () => {
      console.log("Session ended due to inactivity.");
      menuExitSession();
      setShowInactivityDialog(false); // Hide inactivity dialog
      setCountdown(300); // Reset countdown
    };
  
    // Event listener for user activity
    const activityListener = () => {
      resetInactivityTimer(); // Reset inactivity timer on user activity
    };
  
    // Visibility change listener
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        resetInactivityTimer(); // Reset inactivity timer when page is hidden
      }
    };
  
    // Attach activity listeners
    window.addEventListener("mousemove", activityListener);
    window.addEventListener("keydown", activityListener);
    window.addEventListener("scroll", activityListener);
    window.addEventListener("click", activityListener);
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    // Start initial inactivity timer
    resetInactivityTimer();
  
    // Cleanup function to remove event listeners and timers
    return () => {
      window.removeEventListener("mousemove", activityListener);
      window.removeEventListener("keydown", activityListener);
      window.removeEventListener("scroll", activityListener);
      window.removeEventListener("click", activityListener);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(inactivityTimeout.current);
      clearInterval(countdownTimer.current);
    };
  }, []);
  
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setFilteredPatients(
      patients.filter((patient) =>
        `${patient.first_name} ${patient.last_name}`.toLowerCase().includes(query)
      )
    );
  };

  const handlePatientClick = (patient) => {
    if (!selectedPatient || !selectedPatient.id) {
      // If selectedPatient is null or doesn't have an id, set the temp patient and open the dialog
      setTempSelectedPatient(patient); 
      setOpenSessionConfirmationDialog(true);
    } else if (selectedPatient.id !== patient.id) {
      // If selectedPatient has an id and it's different from the clicked patient's id
      setTempSelectedPatient(patient);
      setOpenSessionConfirmationDialog(true);
    }
  };
  

  const handleConfirmSession = async () => {
    try {
      if (!selectedClinic || !tempSelectedPatient) return;
  
      const currentPatientDocRef = selectedPatient
        ? doc(db, "clinics", selectedClinic, "patients", selectedPatient.id)
        : null;
      
      const newPatientDocRef = doc(db, "clinics", selectedClinic, "patients", tempSelectedPatient.id);
  
      // End current active session if exists
      if (currentPatientDocRef) {
        const currentPatientDoc = await getDoc(currentPatientDocRef);
  
        if (currentPatientDoc.exists()) {
          const patientData = currentPatientDoc.data();
          const sessions = patientData.sessions || [];
  
          // Find and update the active session
          const updatedSessions = sessions.map(session => {
            if (session.is_active) {
              return {
                ...session,
                is_active: false,
                end: Timestamp.now(), // Mark the end time
              };
            }
            return session;
          });
  
          // Update Firestore with the modified sessions array
          await updateDoc(currentPatientDocRef, { sessions: updatedSessions });
  
          console.log("Ended current session for patient:", selectedPatient.id);
        }
      } 
  
      // Create new session data for tempSelectedPatient only after confirmation
      const newSession = {
        is_active: true,
        start: Timestamp.now(),
      };
  
      // Add new session for tempSelectedPatient in Firestore
      await updateDoc(newPatientDocRef, {
        sessions: arrayUnion(newSession),
      });
  
      // Set the selected patient and close the confirmation dialog
      setSelectedPatient(tempSelectedPatient);
      setOpenSessionConfirmationDialog(false);
  
      console.log("Session started for patient:", tempSelectedPatient.id);
    } catch (error) {
      console.error("Error handling session:", error);
    }
  };
  
  
  const clinicClicked = (clinicId) => {
    if (selectedPatient) {
      // If a session is active, prompt the user with the confirmation dialog
      setIsExitSessionDialogOpen(true);
      setTempClinicId(clinicId); // Temporarily store the new clinic ID
    } else {
      // If no session is active, directly switch the clinic
      setSelectedClinic(clinicId);
      setSelectedPatient(null);
    }
  };
  
// Function to handle exit session confirmation
const confirmExitSession = async () => {
  if (selectedPatient) {
    try {
      // Reference to the patient's document in Firestore
      const patientDocRef = doc(db, "clinics", selectedClinic, "patients", selectedPatient.id);
      
      // Fetch the current patient document
      const patientDoc = await getDoc(patientDocRef);
      
      if (patientDoc.exists()) {
        const patientData = patientDoc.data();
        const sessions = patientData.sessions || [];

        // Find the active session and update it
        const updatedSessions = sessions.map(session => {
          if (session.is_active) {
            return {
              ...session,
              end: Timestamp.now(), // Set the end time
              is_active: false, // Mark as inactive
            };
          }
          return session; // Return unchanged session
        });

        // Update the Firestore document with the modified sessions array
        await updateDoc(patientDocRef, { sessions: updatedSessions });
        
        console.log("Exited session for patient:", selectedPatient.id);
      }
    } catch (error) {
      console.error("Error ending session:", error);
    }
  }

  // Switch to the new clinic and clear the selected patient
  setSelectedClinic(tempClinicId);
  setSelectedPatient(null);
  setIsExitSessionDialogOpen(false); // Close the dialog
};

const menuExitSession = async () => {
  if (selectedPatient) {
    try {
      // Reference to the patient's document in Firestore
      const patientDocRef = doc(db, "clinics", selectedClinic, "patients", selectedPatient.id);
      
      // Fetch the current patient document
      const patientDoc = await getDoc(patientDocRef);
      
      if (patientDoc.exists()) {
        const patientData = patientDoc.data();
        const sessions = patientData.sessions || [];

        // Find the active session and update it
        const updatedSessions = sessions.map(session => {
          if (session.is_active) {
            return {
              ...session,
              end: Timestamp.now(), // Set the end time
              is_active: false, // Mark as inactive
            };
          }
          return session; // Return unchanged session
        });

        // Update the Firestore document with the modified sessions array
        await updateDoc(patientDocRef, { sessions: updatedSessions });
        
        console.log("Exited session for patient:", selectedPatient.id);
      }
    } catch (error) {
      console.error("Error ending session:", error);
    }
  }
};


// Function to cancel the clinic switch and go back
const cancelClinicSwitch = () => {
  setIsExitSessionDialogOpen(false); // Close the dialog without changing clinic
  setTempClinicId(null);
};

  const toggleClinics = () => {
    setClinicsOpen(!clinicsOpen); // Toggle clinicsOpen state
  };

  const continueSessionFromTimeout = () => {
      //todo call this when the dialog continue button is triggered and remove from here
      clearTimeout(inactivityTimeout.current);
      setShowInactivityDialog(false);
  };

  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
      {/* MenuPane */}
      <MenuPane exitSession={menuExitSession} />

      {/* Main Content */}
      <Grid
        item
        container
        direction="row"
        style={{ flexGrow: 1, display: "flex", marginTop: "75px", padding: "16px" }}
      >
        {/* Left Side: Clinics and Patients */}
        <Box display="flex" style={{ flexDirection: "row", flexShrink: 0 }}>
          {/* Toggle Button */}
          <Button
            onClick={toggleClinics}
            variant="contained"
            style={{
              backgroundColor: "#4BD891",
              color: "white",
              alignSelf: "flex-start",
              marginRight: clinicsOpen ? "16px" : "16px",
              minWidth: "100px",
              padding: "8px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {clinicsOpen ? (
              <>
                <ChevronLeftIcon style={{ color: "white", marginRight: "8px" }} />
                <span>Hide</span>
              </>
            ) : (
              <>
                <ChevronRightIcon style={{ color: "white", marginRight: "8px" }} />
                <span>Expand</span>
              </>
            )}
          </Button>

          {/* Clinics Section */}
          {clinicsOpen && (
            <Box style={{ width: "250px", marginRight: "16px" }}>
              <Typography variant="h5" className="basefont-regular" style={{ marginBottom: "20px" }}>
                Clinics
              </Typography>
              {clinics.map((clinic) => (
                <Box
                  key={clinic.id}
                  onClick={() => clinicClicked(clinic.id)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    backgroundColor: selectedClinic === clinic.id ? "#4BD891" : "transparent",
                    "&:hover": { backgroundColor: selectedClinic === clinic.id ? "#4BD891" : "#f0f0f0" },
                  }}
                >
                  <Typography variant="body1" className="basefont-regular">
                    {clinic.name}
                  </Typography>
                  <ArrowForwardIosIcon fontSize="small" />
                </Box>
              ))}
            </Box>
          )}

          {/* Patients Section */}
          {clinicsOpen && (
            <Box style={{ width: "300px" }}>
              <Typography variant="h5" className="basefont-regular" style={{ marginBottom: "20px" }}>
                Patients
              </Typography>
              <Button
                onClick={() => setOpenPatientIntake(true)}
                variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                + New Patient
              </Button>
              <VitalQueTextField
                type="text"
                label="Search Patients"
                style={{ width: "100%" }}
                onChange={handleSearchChange}
              />
              {filteredPatients.map((patient) => (
                <Tooltip title={patient.needsVideoCall ? "Video session required" : ""} key={patient.id}>
                  <Box
                    onClick={() => handlePatientClick(patient)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                      marginTop: "20px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      backgroundColor: selectedPatient?.id === patient.id ? "#4BD891" : "transparent",
                      "&:hover": { backgroundColor: selectedPatient?.id === patient.id ? "#4BD891" : "#f0f0f0" },
                    }}
                  >
                    <Typography variant="body1" className="basefont-regular">
                      {patient.first_name} {patient.last_name}
                    </Typography>
                    {patient.needsVideoCall && <ReportIcon color="error" />}
                    <ArrowForwardIosIcon fontSize="small" />
                  </Box>
                </Tooltip>
              ))}
            </Box>
          )}
        </Box>

       
        {/* Right Side: Patient Overview */}
        <Box
          style={{
            flexGrow: 1,
            marginLeft: clinicsOpen ? "16px" : "0px",
            transition: "margin-left 0.3s",
          }}
        >
          {selectedPatient ? (
            <PatientOverview patient={selectedPatient} clinicId={selectedClinic} db={db} />
          ) : (
            <Typography variant="h5" className="basefont-regular">
              Select a patient to view details
            </Typography>
          )}
        </Box>
      </Grid>

      {/* Session Confirmation Dialog */}
      <Dialog
        open={openSessionConfirmationDialog}
        onClose={() => setOpenSessionConfirmationDialog(false)}
        aria-labelledby="session-confirmation-dialog-title"
      >
        <DialogTitle id="session-confirmation-dialog-title"  variant="h6" className="basefont-regular">
          Start Treatment Session?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body" className="basefont-regular">Please confirm if you want to start a treatment session for this patient.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSessionConfirmationDialog(false)} variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}>Cancel</Button>
          <Button onClick={handleConfirmSession} color="primary" autoFocus variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}>
            Start Session
          </Button>
        </DialogActions>
      </Dialog>
         {/* Inactivity Warning Dialog */}
         <Dialog open={showInactivityDialog} onClose={() => setShowInactivityDialog(false)}>
        <DialogTitle className="basefont-regular">Inactive Warning</DialogTitle>
        <DialogContent>
          <Typography variant="body1" className="basefont-regular">You may be logged out due to inactivity. Please interact with the page to remain active.</Typography>
          <Typography variant="body2" className="basefont-regular" color="textSecondary">
          Time remaining: {countdown} seconds
        </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInactivityDialog(false)} color="primary" autoFocus variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}>Stay Logged In</Button>
        </DialogActions>
      </Dialog>
       {/* Exit session dialog */}
      <Dialog
      open={isExitSessionDialogOpen}
      onClose={cancelClinicSwitch}
      aria-labelledby="exit-session-dialog-title"
      aria-describedby="exit-session-dialog-description"
    >
      <DialogTitle  className="basefont-regular" id="exit-session-dialog-title">Exit Patient Session</DialogTitle>
      <DialogContent>
        <DialogTitle variant="body1" className="basefont-regular">
          Are you sure you want to exit the current patient's session?
        </DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClinicSwitch} color="primary" autoFocus variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}>
          Go Back
        </Button>
        <Button onClick={confirmExitSession} color="primary" autoFocus variant="outlined"
                className="active-font"
                style={{
                  marginBottom: "20px",
                  borderColor: "#4BD891",
                  color: "black",
                  fontSize: "14px",
                  textTransform: "none",
                }}>
          Exit Session
        </Button>
      </DialogActions>
    </Dialog>

     {/* Patient Intake */}
     <Dialog open={openPatientIntake} onClose={() => setOpenPatientIntake(false)}>
      <PatientIntake selectedClinic={selectedClinic} projectId={sessionStorage.getItem("userUID")} db={db}/>
      </Dialog>
    </Grid>
  );
};

export default ProviderHome;
